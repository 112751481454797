'use strict';

// Vendors
import $ from 'jquery';

// Components
import slider from './components/slider';
import nav from './components/nav';


// App
$(function() {

    slider();
    nav();

});
