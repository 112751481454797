'use strict';

import $ from 'jquery';

export default function() {

    let $body = $('body');

    $('.nav-toggle').on('click', function () {
        $body.addClass('open-nav');
    });

    $('.nav-close').on('click', function () {
        $body.removeClass('open-nav');
    });
}
