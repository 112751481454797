'use strict';

import $ from 'jquery';
import slick from 'slick-carousel';

export default function() {

    $(".slider", this).each(function () {

        $(".slider__content", this).slick({
            infinite: true,
            arrows: false,
            slidesToShow: 8,
            slidesToScroll: 1,
            touchThreshold: 100,
            slide: '.slider__item',
            speed: 500,
            dots: false,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 6
                    }
                },
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 501,
                    settings: {
                        slidesToShow: 2
                    }
                }
            ]
        });
    });

}
